import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import SwiperCore, { Lazy, Swiper as SwiperJs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ArrowRight } from "../../images/icons/arrow-right";
import { ArrowLeft } from "../../images/icons/arrow-left";
import { useInView } from "react-intersection-observer";
import { ReactComponent as ArrowPrevMobile } from "../../images/icons/arrow-left-mobile.svg";
import { ReactComponent as ArrowNextMobile } from "../../images/icons/arrow-right-mobile.svg";
import { ImageInterface, Slug } from "../../types/SanityTypes";
import Accessibility from "../Accessibility";
import Slide from "../Slide";
import "./styles.scss";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { event12 } from "../../analytics/event12";
import { event66 } from "../../analytics/event66";
import CardRenderer from "../CardRenderer";

interface PlaylistSliderInterface {
  _rawImage: ImageInterface;
  url: string;
  title: string;
  // For Preview
  image?: ImageInterface;
}

interface GenericSliderInterface {
  _rawImage: ImageInterface;
  url: string;
  title: string;
  // For Preview
  image?: ImageInterface;
  sliderType: {
    name: string;
    description: string;
  };
}

interface ArticleSliderInterface {
  _id: string;
  _rawHeroImage: ImageInterface;
  slug: {
    current: string;
  };
  headline: string;
  parentSection?: Slug & {
    parentSection?: Slug;
  };
  // For Preview
  heroImage?: ImageInterface;
}

interface ProductSliderInterface {
  _id: string;
  _rawImages: ImageInterface[];
  subBrand: {
    name: string;
  };
  displayReviews: boolean;
  name: string;
  paUPC: string;
  slug: {
    current: string;
  };
  paSmartProductId: string;
  variant?: {
    primaryColour?: {
      hex?: string;
    };
  };
  // For Preview
  images?: ImageInterface[];
  sliderType: {
    name: string;
    description: string;
  };
}

type SliderContentUnionType =
  | PlaylistSliderInterface[]
  | GenericSliderInterface[]
  | ArticleSliderInterface[]
  | ProductSliderInterface[]
  | ImageInterface[];

export interface SliderBlockInterface {
  data: SliderContentUnionType;
  textColor?: string;
  type: string;
  variation?: string;
  _rawImage?: ImageInterface;
  url?: string;
  title?: string;
}

SwiperCore.use([Lazy]);

const SliderBlock: FunctionComponent<SliderBlockInterface> = ({ data, type, textColor, variation }) => {
  const { sanityAccessibility, sanitySiteSettings } = useContext(LocalizedContext);
  const { previous, next } = sanityAccessibility || {};
  const [width, setWidth] = useState(992);
  const { sanityProductLandingPage, sanitySearch } = useContext(LocalizedContext);
  const elementNum = data.length;
  const slidesPerView = type === "collection-slider" ? 4 : 3;
  const slidesPerViewMobile = type === "collection-slider" ? 2.25 : 1.5;
  const slidesPerGroup = type === "collection-slider" ? 4 : 1;
  const slidesPerGroupMobile = type === "collection-slider" ? 2 : 1;
  const loopSlides = type === "collection-slider" ? false : elementNum >= slidesPerView;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "5px 0px"
  });

  const [swiper, setSwiper] = useState(new SwiperJs(""));

  const swiperNext = () => {
    if (swiper) {
      swiper.slideNext();
      event66(next ? next : "slider next");
    }
  };

  const swiperPrev = () => {
    if (swiper) {
      swiper.slidePrev();
      event66(previous ? previous : "slider previous");
    }
  };

  const toggleRightArrowChange = () => {
    return width >= 992 ? <ArrowRight /> : <ArrowNextMobile />;
  };

  const toggleLeftArrowChange = () => {
    return width >= 992 ? <ArrowLeft /> : <ArrowPrevMobile />;
  };

  const setBreakPoints =
    type === "multiple-products"
      ? undefined
      : {
          375: {
            centeredSlides: false,
            initialSlide: 0,
            spaceBetween: 20,
            slidesPerView: slidesPerViewMobile,
            slidesPerGroup: slidesPerGroupMobile,
            scrollbar: {
              draggable: true
            }
          },
          768: {
            centeredSlides: false,
            initialSlide: 0,
            spaceBetween: 25,
            slidesPerView,
            slidesPerGroup,
            scrollbar: {
              draggable: true
            }
          }
        };

  const sliderSize = type === "multiple-products" ? "multiple-products-slider-size" : "slider-size";

  const renderSlides = () => {
    if (type === "playlist") {
      return (data as unknown as PlaylistSliderInterface[]).map((item: PlaylistSliderInterface, index: number) => {
        const coverImage = item._rawImage || item.image;
        return (
          <React.Fragment key={`${index}`}>
            <SwiperSlide
              className="swiper-slide-animation"
              key={`slider-${index}`}
              data-testid={`playlist-slide-${index}`}
            >
              <Slide
                key={index}
                itemPosition={`${index}`}
                link={item.url}
                image={coverImage}
                heading={item.title}
                type={"playlist"}
                textColor={textColor}
                inView={inView}
              />
            </SwiperSlide>
          </React.Fragment>
        );
      });
    }
    if (type === "externalReferences") {
      return (data as unknown as GenericSliderInterface[]).map((item: GenericSliderInterface, index: number) => {
        const coverImage = item._rawImage || item.image;
        return (
          <React.Fragment key={`${index}`}>
            <SwiperSlide
              className={`swiper-slide-animation ${item}`}
              key={`slider-${index}`}
              data-testid={`playlist-slide-${index}`}
            >
              <Slide
                key={index}
                itemPosition={`${index}`}
                link={item.url}
                image={coverImage}
                heading={item.title}
                type={variation}
                textColor={textColor}
                inView={inView}
              />
            </SwiperSlide>
          </React.Fragment>
        );
      });
    }
    if (type === "article") {
      return (data as ArticleSliderInterface[]).map((item: ArticleSliderInterface, index: number) => {
        const image = item._rawHeroImage || item.heroImage;
        const parentPath = item.parentSection?.parentSection
          ? `${item.parentSection?.parentSection.slug.current}/${item.parentSection?.slug.current}`
          : item.parentSection?.slug.current;
        return (
          <React.Fragment key={`${index}`}>
            <SwiperSlide className="swiper-slide-animation" key={index}>
              <Slide
                key={index}
                itemPosition={`${index}`}
                link={`${parentPath}/${item.slug.current}`}
                image={image}
                heading={item.headline}
                type={"article"}
                textColor={textColor}
                inView={inView}
                id={item._id}
              />
            </SwiperSlide>
          </React.Fragment>
        );
      });
    }
    if (type === "product") {
      return (data as ProductSliderInterface[]).map((item: ProductSliderInterface, index: number) => {
        const imageArray = item?._rawImages || item?.images || [];
        const renderImage = imageArray.length < 1 ? (sanitySearch?._rawPlaceholder as ImageInterface) : imageArray[0];
        const onClick = () =>
          event12(item._id, item.name, item.subBrand.name, sanitySiteSettings.brandName, index, "Product Slider");
        return (
          <React.Fragment key={`${index}`}>
            <SwiperSlide className="swiper-slide-animation" key={index} data-testid="product-slider">
              {!item ? (
                <Slide
                  key={index}
                  itemPosition={`${index}`}
                  link={sanityProductLandingPage?.slug.current}
                  image={sanitySearch?._rawPlaceholder as ImageInterface}
                  heading={sanityProductLandingPage?.heading as string}
                  inView={inView}
                  id={sanityProductLandingPage?._id}
                />
              ) : (
                <Slide
                  key={index}
                  itemPosition={`${index}`}
                  link={`${sanityProductLandingPage?.slug.current}/${item.slug?.current}`}
                  image={renderImage}
                  heading={item.name}
                  inView={inView}
                  id={item._id}
                  upc={item.paUPC || item.paSmartProductId}
                  type="product"
                  displayReviews={item.displayReviews}
                  onClick={onClick}
                />
              )}
            </SwiperSlide>
          </React.Fragment>
        );
      });
    }
    if (type === "multiple-products") {
      return (data as ImageInterface[]).map((item: ImageInterface, index: number) => {
        return (
          <React.Fragment key={`${index}`}>
            <SwiperSlide key={index} data-testid="multiple-products-slider">
              {!item ? (
                <Slide key={index} itemPosition={`${index}`} image={item as ImageInterface} inView={inView} />
              ) : (
                <Slide key={index} itemPosition={`${index}`} image={item} inView={inView} />
              )}
            </SwiperSlide>
          </React.Fragment>
        );
      });
    }
    if (type === "collection-slider") {
      return (data as any).map((item: any, index: number) => (
        <React.Fragment key={`${index}`}>
          <SwiperSlide key={index} data-testid="collection-slider" className={`el-${index}`}>
            <CardRenderer key={index} card={item} listingType="collectionSlide" />
          </SwiperSlide>
        </React.Fragment>
      ));
    }
  };

  return (
    <div ref={ref} className="slider-block" data-testid="slider-block">
      <div className={sliderSize}>
        <div className="exo-swiper-wrapper" data-testid="swiper">
          <Swiper
            key={type}
            preloadImages={true}
            centeredSlides={true}
            centeredSlidesBounds={true}
            initialSlide={0}
            slidesPerView={"auto"}
            spaceBetween={10}
            simulateTouch={elementNum >= slidesPerView || (elementNum == 2 && width < 768)}
            lazy={true}
            freeMode={true}
            watchSlidesVisibility={true}
            loopedSlides={slidesPerView}
            loop={loopSlides}
            scrollbar={{ draggable: true }}
            onSwiper={setSwiper}
            preventClicks={false}
            breakpoints={setBreakPoints}
            className={elementNum >= slidesPerView || (elementNum == 2 && width < 768) ? "" : "swiper-no-swiping"}
          >
            {renderSlides()}
          </Swiper>
        </div>
        {elementNum >= slidesPerView || (elementNum == 2 && width < 768) ? (
          <>
            <span className="slider-button-container slider-prev" data-testid="prev-col">
              <button
                data-anchor-link
                className="prev-button"
                type="button"
                onClick={swiperPrev}
                data-testid="prev-button"
              >
                {toggleLeftArrowChange()}
                <Accessibility location={"previous"} />
              </button>
            </span>
            <span className="slider-button-container slider-next" data-testid="next-col">
              <button
                data-anchor-link
                className="next-button"
                type="button"
                onClick={swiperNext}
                data-testid="next-button"
              >
                {toggleRightArrowChange()}
                <Accessibility location={"next"} />
              </button>
            </span>
          </>
        ) : null}
      </div>
    </div>
  );
};
export default SliderBlock;
