import React from "react";

export const ArrowLeft = () => {
  return (
    <svg width="54px" height="54px" viewBox="0 0 74 74" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Arrow left</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-21.000000, -670.000000)">
          <g transform="translate(-2285.000000, 84.000000)">
            <g transform="translate(2306.000000, 586.000000)">
              <circle fill="#181818" cx="37" cy="37" r="37"></circle>
              <path
                d="M56,37.5325132 C56,37.9226714 55.6072696,38.3128295 55.2145391,38.3128295 L23.6182029,38.3128295 L39.9819709,54.56942 C40.2437912,54.8295255 40.2437912,55.3497364 39.9819709,55.7398946 C39.8510607,55.8699473 39.5892405,56 39.4583303,56 C39.3274202,56 39.0655999,55.8699473 38.9346897,55.7398946 L21.1309101,38.0527241 C21,37.9226714 21,37.9226714 21,37.7926186 C21,37.6625659 21,37.5325132 21,37.5325132 C21,37.4024605 21,37.2724077 21,37.2724077 C21,37.142355 21.1309101,37.142355 21.1309101,37.0123023 L39.0655999,19.1950791 C39.3274202,18.9349736 39.8510607,18.9349736 40.2437912,19.1950791 C40.5056115,19.4551845 40.5056115,19.9753954 40.2437912,20.3655536 L23.6182029,36.7521968 L55.2145391,36.7521968 C55.6072696,36.7521968 56,37.142355 56,37.5325132 Z"
                stroke="#FFFFFF"
                strokeWidth="3"
                fill="#181818"
                transform="translate(38.500000, 37.500000) scale(-1, 1) rotate(-180.000000) translate(-38.500000, -37.500000) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
