import React, { FunctionComponent, MouseEvent, useContext } from "react";
import { Link } from "../Link";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface } from "../../types/SanityTypes";
import Accessibility from "../Accessibility";
import { Play } from "../../images/icons/play";
import { LocalizedContext } from "../../services/LocalizedContextService";
import ProductRatings from "../ProductRatings";
import "../SliderBlock/styles.scss";

interface SlideInterface {
  id?: string;
  heading?: string;
  image: ImageInterface;
  link?: string;
  itemPosition: string;
  type?: string;
  productColor?: string;
  textColor?: string;
  inView?: boolean;
  upc?: string;
  displayReviews?: boolean;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
}

const Slide: FunctionComponent<SlideInterface> = ({
  link,
  itemPosition,
  image,
  heading,
  type,
  productColor = "blue",
  textColor,
  inView,
  id = "",
  upc = "",
  displayReviews,
  onClick = () => {} // eslint-disable-line @typescript-eslint/no-empty-function
}) => {
  const { readMore } = useContext(LocalizedContext).sanityAccessibility || {};
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: "sheamoisture-stg-us"
  });

  const itemImage = (
    <>
      {image && (
        <>
          {inView ? (
            <picture>
              <source
                media={"(min-width: 1200px)"}
                srcSet={urlBuilder.image(image).auto("format").quality(80).width(386).height(386).url() as string}
              />
              <img
                src={urlBuilder.image(image).auto("format").quality(80).width(365).height(365).url() as string}
                alt={image.alt}
                className={"img-fluid image-position-" + itemPosition}
                style={{
                  backgroundImage: `linear-gradient(${productColor}, ${productColor}), linear-gradient(${productColor}, ${productColor})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "31px 75%, 50% 31px"
                }}
                data-testid={`rc-slide-${itemPosition}-${type}`}
              />
            </picture>
          ) : null}
        </>
      )}
    </>
  );

  const linkedItem = (
    <>
      {type === "playlist" ? (
        itemImage
      ) : (
        <div className={"image-container image-container-" + itemPosition}>{itemImage}</div>
      )}
      {type === "playlist" ? (
        <div className="play-svg img-fluid">
          <Play />
        </div>
      ) : (
        <hr />
      )}
      {heading && (
        <>
          <div className="title subhead">{heading}</div>
          {type === "article" && (
            <div style={{ color: textColor }} className="readMore">
              <Accessibility location={readMore} />
            </div>
          )}
          {(type === "playlist" || type === "partners") && <Accessibility openNewWindow={true} />}
          {type === "product" && displayReviews && <ProductRatings productName={heading} upc={upc} template="pdp" />}
        </>
      )}
    </>
  );

  const renderLinkedSlide = () => {
    if (type === "article") {
      return (
        <Link _id={id} to={link as string} data-analytics-event63="" data-analytics-event4="">
          {linkedItem}
        </Link>
      );
    } else if (type === "playlist" || type === "partners") {
      return (
        <a href={link} rel="noreferrer">
          {linkedItem}
        </a>
      );
    }
    return (
      <Link _id={id} to={link as string} onClick={onClick}>
        {linkedItem}
      </Link>
    );
  };

  return (
    <>
      {link ? (
        renderLinkedSlide()
      ) : (
        <div className={"multiple-products image-container image-container-" + itemPosition}>{itemImage}</div>
      )}
    </>
  );
};
export default Slide;
